import { FC } from 'react'
import styled from 'styled-components'

interface Props {
  title: string
}

const Title = styled.h3`
  padding-bottom: 4px;
  font-size: 16px;
  border-bottom: 1px #959494 solid;
`

const SubTitle: FC<Props> = ({ title }) => {
  return <Title>{title}</Title>
}

export default SubTitle
