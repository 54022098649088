import { FC } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import sortBy from 'lodash.sortby'
import SubTitle from 'src/components/SubTitle/SubTitle'
import { IArtist } from 'src/types'

const ArtistContainer = styled.section`
  margin: 48px 0;

  figure {
    margin-right: 14px;
  }
`

const ArtistWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;
`

export const Image = styled.img`
  width: 174px;
  border: 1px #efefef solid;
  padding: 2px;
  height: 215px;
`

export const Figcaption = styled.figcaption`
  margin-top: 8px;
  text-align: center;
  color: #000;
`

export interface Props {
  artists: IArtist[]
}

const Artist: FC<Props> = ({ artists }) => {
  return (
    <ArtistContainer>
      <SubTitle title="书画名家" />
      <ArtistWrapper>
        {sortBy(artists, 'createdAt').map((artist) => {
          const { _id, name, avatarUrl } = artist
          return (
            <Link href={`artist/${_id}`} key={name}>
              <a>
                <figure>
                  <Image src={avatarUrl} alt={name} />
                  <Figcaption>{name}</Figcaption>
                </figure>
              </a>
            </Link>
          )
        })}
      </ArtistWrapper>
    </ArtistContainer>
  )
}

export default Artist
